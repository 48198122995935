import { cx } from '@emotion/css';

import { DialogPanel, Dialog as HeadlessuiDialog } from '@headlessui/react';
import type { CSSProperties, HTMLAttributes } from 'react';

export type DialogSimpleProps = {
  isOpen: boolean;
  onClose: () => void;
  dialogPanelClassName?: string;
  dialogPanelStyle?: CSSProperties;
} & HTMLAttributes<HTMLDivElement>;

export const DialogSimple = ({
  isOpen,
  onClose,
  children,
  dialogPanelClassName,
  dialogPanelStyle,
}: DialogSimpleProps) => {
  return (
    <HeadlessuiDialog
      open={isOpen}
      as="div"
      className="relative z-10 focus:outline-none"
      onClose={onClose}
    >
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-[#000000AA]">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className={cx(
              'data-[closed]:transform-[scale(95%)] w-full max-w-screen-md rounded-xl border bg-white p-6 backdrop-blur-2xl duration-100 ease-out data-[closed]:opacity-0',
              dialogPanelClassName,
            )}
            style={dialogPanelStyle}
          >
            {children}
          </DialogPanel>
        </div>
      </div>
    </HeadlessuiDialog>
  );
};
