import './styles/main.scss';

import { ConfigProvider } from 'antd';
import {
  AuthPage,
  HistoryPage,
  InstallmentPage,
  ProfilePage,
  RecoveryPage,
} from 'pages';
import { UUID } from 'uuid-generator-ts';
import { Layout } from 'widgets';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { XSessionIdKey } from 'shared/contants';
import { AuthContext } from 'shared/hooks';
import { RoutesPath } from 'shared/routes-path';

sessionStorage.setItem(XSessionIdKey, UUID.createUUID());

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

export const App = () => {
  const [isAuth, setIsAuth] = useState(!!sessionStorage.getItem('accessToken'));

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#991e66',
          },
        }}
      >
        {' '}
        <BrowserRouter>
          <AuthContext.Provider
            value={{
              isAuth,
              setIsAuth,
            }}
          >
            <Pages />
          </AuthContext.Provider>
        </BrowserRouter>
      </ConfigProvider>
      <ToastContainer closeOnClick theme="colored" />
    </QueryClientProvider>
  );
};

const Pages = () => {
  return (
    <Routes>
      <Route path={RoutesPath.AUTH} element={<AuthPage />} />
      <Route path={RoutesPath.RECOVERY_PASSWORD} element={<RecoveryPage />} />
      <Route element={<Layout />}>
        <Route path={RoutesPath.PROFILE} element={<ProfilePage />} />
        <Route path={RoutesPath.INSTALLMENT} element={<InstallmentPage />} />
        <Route path={RoutesPath.HISTORY} element={<HistoryPage />} />
      </Route>
      <Route path="*" element={<Navigate replace to={RoutesPath.PROFILE} />} />
    </Routes>
  );
};
