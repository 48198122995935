import { format } from 'date-fns';

import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { AddCardDialog } from 'features/add-card/ui';

import { ProfileApi } from 'entities/profile';

import { detectCardType } from 'shared/lib';
import { Button, ButtonColored, Spinner } from 'shared/ui';

export const ProfileInfo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useQuery({
    queryKey: [ProfileApi.KEY],
    queryFn: ProfileApi.getProfile,
  });

  if (!data) return <Spinner />;

  return (
    <>
      <div className="w-2/5">
        <div className="flex flex-col justify-center gap-4">
          <p className="text-[18px]">Профиль</p>
          <div className="mt-4 flex flex-col gap-2">
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="font-second text-sm leading-[120%]">
                Неизрасходованный лимит
              </span>
              <span className="font-second text-right text-[16px] font-bold">
                {data.remainingPaymentLimit} ₽
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="font-second text-sm leading-[120%]">ФИО</span>
              <span className="font-second text-right text-[16px] font-bold">
                {data.fullName}
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="font-second text-sm leading-[120%]">
                Дата рождения
              </span>
              <span className="font-second text-right text-[16px] font-bold">
                {format(new Date(data.birthDate), 'dd.MM.yyyy г.')}
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="font-second text-sm leading-[120%]">
                Номер телефона
              </span>
              <span className="font-second text-right text-[16px] font-bold">
                {data.phoneNumber}
              </span>
            </div>
            <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
              <span className="font-second text-sm leading-[120%]">Email</span>
              <span className="text-right text-[16px] font-bold">
                {data.email}
              </span>
            </div>
            <span>
              <a
                href="/"
                className="cursor-pointer underline hover:text-brand-main-hover"
              >
                Договор-оферту
              </a>
            </span>
          </div>

          <p className="text-[18px]">Карты</p>
          <div className="relative mt-4 flex flex-col gap-2">
            {data.attachedCreditCards.map((card) => (
              <div
                key={card}
                className="grid h-8 grid-cols-[1fr_1fr_1fr] items-center border-b"
              >
                <span className="text-[16px]">{detectCardType(card)}</span>
                <span />
                <span className="text-right text-[16px] font-bold">{card}</span>
                <Button
                  className="absolute -right-52 h-fit border-0 text-start"
                  onClick={() => setIsOpen(true)}
                >
                  <span className="w-fit border-b border-black text-black">
                    Удалить
                  </span>
                </Button>
              </div>
            ))}
            <ButtonColored
              className="mt-4 w-[233px]"
              onClick={() => setIsOpen(true)}
            >
              Привязвать новую карту
            </ButtonColored>
          </div>
        </div>
      </div>
      <AddCardDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
