import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { AuthApi } from 'shared/api';
import { useAuthContext } from 'shared/hooks';
import { RoutesPath } from 'shared/routes-path';

export const LogoutButton = () => {
  const { setIsAuth } = useAuthContext();
  const navigate = useNavigate();

  const logoutMutation = useMutation({
    mutationFn: AuthApi.logout,
    onMutate: () => {
      setIsAuth(false);
      navigate(RoutesPath.AUTH);

      setTimeout(() => {
        sessionStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      });
    },
  });

  const onClick = () => {
    logoutMutation.mutate({
      accessToken: sessionStorage.getItem('accessToken')!,
      refreshToken: localStorage.getItem('refreshToken')!,
    });
  };

  return (
    <button onClick={onClick}>
      <svg
        width="21"
        height="23"
        viewBox="0 0 21 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 7.75L12.6245 11.5L9.5 7.75ZM9.5 15.25L12.6245 11.5L9.5 15.25Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.75 11.5H1M19.075 22H5.575V16M19.075 1H5.575V7M19.6 22V1"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
};
