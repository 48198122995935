import { Button } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { AddCardDialog } from 'features/add-card/ui';

import { ProfileApi } from 'entities/profile';

import {
  ButtonColored,
  DialogSimple,
  DialogSimpleProps,
  Spinner,
} from 'shared/ui';

type AddCard = {
  onClick?: () => void;
} & DialogSimpleProps;

export const PayDialog = ({ isOpen, onClose }: AddCard) => {
  const [isOpenNewCard, setIsOpenNewCard] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const { data } = useQuery({
    queryKey: [ProfileApi.KEY],
    queryFn: ProfileApi.getProfile,
  });

  if (!data) return <Spinner />;

  const handlePaymentMethodClick = (method: string) => {
    if (selectedPaymentMethod === method) {
      setSelectedPaymentMethod('');
      return;
    }
    setSelectedPaymentMethod(method);
  };

  return (
    <>
      <DialogSimple isOpen={isOpen} onClose={console.log}>
        <div className="flex size-full">
          <form className="m-auto flex w-[600px] flex-col gap-4 self-center bg-white p-12">
            <p className="flex flex-col text-justify text-xl font-normal md:text-2xl">
              <span className="text-[30px]">Способы оплаты</span>
            </p>
            <div className="flex flex-col gap-4">
              {data.attachedCreditCards.map((card, index) => (
                <ButtonColored
                  key={index}
                  onClick={() => handlePaymentMethodClick(`card_${index}`)}
                  className={
                    selectedPaymentMethod === `card_${index}`
                      ? 'bg-brand-main-hover'
                      : ''
                  }
                >
                  {card}
                </ButtonColored>
              ))}

              <ButtonColored
                onClick={() => handlePaymentMethodClick('arbitrary_card')}
                className={
                  selectedPaymentMethod === 'arbitrary_card'
                    ? 'bg-brand-main-hover'
                    : ''
                }
              >
                Оплата произвольной картой
              </ButtonColored>
              <ButtonColored
                onClick={() => handlePaymentMethodClick('sbp')}
                className={
                  selectedPaymentMethod === 'sbp' ? 'bg-brand-main-hover' : ''
                }
              >
                Оплата по СБП
              </ButtonColored>
            </div>
            <div className="flex gap-2">
              <Button onClick={onClose} disabled={!selectedPaymentMethod}>
                Оплатить
              </Button>
              <Button onClick={() => setIsOpenNewCard(true)}>
                Привязать карту
              </Button>
            </div>
          </form>
        </div>
      </DialogSimple>
      <AddCardDialog
        isOpen={isOpenNewCard}
        onClose={() => setIsOpenNewCard(false)}
      />
    </>
  );
};
