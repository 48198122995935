import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';

import { Field, Input } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthApi } from 'shared/api';
import { onAxiosError } from 'shared/lib';
import { RoutesPath } from 'shared/routes-path';
import type { ChangePassword } from 'shared/typings';
import { ButtonBack, ButtonColored } from 'shared/ui';

const schema = yup.object({
  newPassword: yup
    .string()
    .trim()
    .min(8, 'Пароль должен содержать минимум 10 символов')
    .required('Введите новый пароль'),
  confirmNewPassword: yup
    .string()
    .trim()
    .min(8, 'Пароль должен содержать минимум 10 символов')
    .required('Подтвердите новый пароль')
    .oneOf([yup.ref('newPassword')], 'Пароли не совпадают'),
});

export const StepChangePassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const signInMutation = useMutation({
    mutationFn: (data: ChangePassword) => AuthApi.changePassword(data),
    onSuccess: (data) => {
      if (data.success) {
        navigate(RoutesPath.AUTH);
      }
    },
    onError: onAxiosError,
  });

  const onSubmit = async (data: ChangePassword) => {
    if (data.newPassword !== data.confirmNewPassword) {
      toast.info('Пароли не совпадают');
      return;
    }
    signInMutation.mutate({ newPassword: data.newPassword });
  };

  return (
    <div className="flex size-full">
      <form
        className="m-auto flex w-[600px] flex-col gap-4 self-center bg-white p-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="flex flex-col text-justify text-xl font-normal md:text-2xl">
          <span className="text-[30px]">Восстановление пароля</span>
        </p>
        <div className="flex flex-col gap-4">
          <Field className="flex w-full flex-col gap-2">
            <Input
              {...register('newPassword')}
              placeholder="Введите данные"
              className={cx(
                'flex items-center border border-[#575757] py-1.5 px-3 text-sm/6 text-[#727272] h-[60px] rounded-md w-full',
                'focus:outline-none hover:border-brand-main-hover',
              )}
            />
          </Field>
          <Field className="flex w-full flex-col gap-2">
            <Input
              {...register('confirmNewPassword')}
              placeholder="Введите данные"
              className={cx(
                'flex items-center border border-[#575757] py-1.5 px-3 text-sm/6 text-[#727272] h-[60px] rounded-md w-full',
                'focus:outline-none hover:border-brand-main-hover',
              )}
            />
          </Field>
          {errors &&
            Object.values(errors).map(({ message }) => (
              <span key={message} className="">
                {message}
              </span>
            ))}
        </div>
        <div className="flex gap-2">
          <ButtonColored
            text="Восстановить пароль"
            disabled={signInMutation.isPending}
          />
          <ButtonBack />
        </div>
      </form>
    </div>
  );
};
