export const detectCardType = (cardNumber: string) => {
  const firstFourDigits = cardNumber.slice(0, 4);

  if (firstFourDigits.startsWith('4')) {
    return 'Visa';
  }

  if (
    (firstFourDigits >= '51' && firstFourDigits <= '55') ||
    (firstFourDigits >= '2221' && firstFourDigits <= '2720')
  ) {
    return 'Mastercard';
  }

  if (firstFourDigits >= '2200' && firstFourDigits <= '2204') {
    return 'Мир';
  }

  return 'Карта';
};
