import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';
import { InferType } from 'yup';

import { Button, Field, Input } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { CardApi } from 'entities/card';

import { onAxiosError } from 'shared/lib';
import { ButtonColored, DialogSimple, DialogSimpleProps } from 'shared/ui';

type AddCard = {
  onClick?: () => void;
} & DialogSimpleProps;

const schema = yup.object({
  newCard: yup.string().trim().min(16).required(),
});

export const AddCardDialog = ({ isOpen, onClose }: AddCard) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const addCardMutation = useMutation({
    mutationFn: (data: InferType<typeof schema>) => CardApi.addCard(data),
    onSuccess: () => {
      toast.success('Карта добавлена');
      onClose();
    },
    onError: onAxiosError,
  });

  const onSubmit = (data: InferType<typeof schema>) => {
    addCardMutation.mutate(data);
  };

  return (
    <DialogSimple isOpen={isOpen} onClose={console.log}>
      <div className="flex size-full">
        <form
          className="m-auto flex w-[600px] flex-col gap-4 self-center bg-white p-12"
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className="flex flex-col text-justify text-xl font-normal md:text-2xl">
            <span className="text-[30px]">Добавление новой карты</span>
          </p>
          <div className="flex flex-col gap-4">
            <Field className="flex w-full flex-col gap-2">
              <Input
                {...register('newCard')}
                placeholder="Номер карты"
                className={cx(
                  'flex items-center border border-[#575757] py-1.5 px-3 text-sm/6 text-[#727272] h-[60px] rounded-md w-full',
                  'focus:outline-none hover:border-brand-main-hover',
                )}
              />
            </Field>
            {errors &&
              Object.values(errors).map(({ message }) => (
                <span key={message} className="">
                  {message}
                </span>
              ))}
          </div>
          <div className="flex gap-2">
            <ButtonColored
              text="Восстановить пароль"
              disabled={addCardMutation.isPending}
            />
            <Button onClick={onClose}>Отмена</Button>
          </div>
        </form>
      </div>
    </DialogSimple>
  );
};
