import { axiosInstance } from 'shared/api/axios.defaults';

import type { PaymentParams } from './model';

export class PaymentApi {
  static readonly KEY = 'order';

  static async initPayment(params: PaymentParams): Promise<string> {
    const { data } = await axiosInstance.post(`/payment/init-payment`, params);
    return data;
  }
}
