import { useState } from 'react';

import type { StepType } from './ui';
import { Steps } from './ui';

export const RecoveryPage = () => {
  const [step, setStep] = useState<StepType>('RECOVERY');

  return (
    <>
      {step === 'RECOVERY' && <Steps.Recovery setStep={setStep} />}
      {step === 'APPROVAL' && <Steps.ApproveCode setStep={setStep} />}
      {step === 'CHANGE_PASSWORD' && <Steps.ChangePassword />}
    </>
  );
};
