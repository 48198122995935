import type { Payment, PaymentPlan } from '../model';

export const getCurrentStage = (
  paymentPlans: PaymentPlan[],
  payments: Payment[],
) => {
  let remainingAmount = payments.reduce((sum, payment) => sum + payment.sum, 0);

  for (let i = 0; i < paymentPlans.length; i++) {
    const stage = paymentPlans[i];

    if (remainingAmount >= stage.sum) {
      // Если оплачено больше или равно сумме этапа, переходим к следующему этапу
      remainingAmount -= stage.sum;
    } else {
      // Если оплачено меньше суммы этапа, возвращаем текущий этап и оставшуюся сумму
      return {
        stage: stage.stage,
        date: stage.date,
        paid: remainingAmount,
        total: stage.sum,
        isPartial: remainingAmount > 0,
      };
    }
  }

  return {
    stage: null,
    message: 'Все этапы оплачены',
  };
};
