import { cx } from '@emotion/css';

import { format } from 'date-fns';

import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { PayDialog } from 'features/pay/ui';

import { OrderApi } from 'entities/order';
import { PaymentApi } from 'entities/payment';
import { getCurrentStage } from 'entities/payment/lib';

import { Button, ButtonColored, Spinner } from 'shared/ui';

export const InstallmentInfo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useQuery({
    queryKey: [OrderApi.KEY, 'getOrders'],
    queryFn: OrderApi.getOrders,
  });

  const { data: order } = useQuery({
    queryKey: [OrderApi.KEY, 'getOrder'],
    queryFn: () => OrderApi.getOrder(data![0].guid),
    enabled: !!data,
  });

  const paymentMutation = useMutation({
    mutationFn: (sum: number) =>
      PaymentApi.initPayment({
        orderGuid: data![0].guid,
        sum,
      }),
    onSuccess: (link) => {
      window.open(link, '_blank');
    },
  });

  if (!order) return <Spinner />;

  const stage = getCurrentStage(order.paymentPlan, order.payments);

  return (
    <div>
      <div className="flex flex-col justify-center gap-4">
        <div className="flex flex-row gap-10">
          <div>
            <p className="text-[18px]">Условия рассрочки</p>
            <div className="font-second mt-4 flex flex-col gap-2">
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                <span className="text-sm leading-[120%]">Номер договора:</span>
                <span className="text-right text-sm font-bold">
                  {order.number}
                </span>
              </div>
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                <span className="text-sm leading-[120%]">Дата оформления:</span>
                <span className="text-right text-[16px] font-bold">
                  {format(new Date(order.date), 'dd.MM.yyyy')}
                </span>
              </div>
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                <span className="text-sm leading-[120%]">Сумма заказа:</span>
                <span className="text-right text-[16px] font-bold">
                  {order.sum} ₽
                </span>
              </div>
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                <span className="text-sm leading-[120%]">
                  Сумма в рассрочку:
                </span>
                <span className="text-right text-[16px] font-bold">
                  {order.sum} ₽
                </span>
              </div>
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center border-b">
                <span className="text-sm leading-[120%]">
                  Первоначальный платеж:
                </span>
                <span className="text-right text-[16px] font-bold">
                  {order.payments[0].sum} ₽
                </span>
              </div>
              <span>
                <a
                  href="/"
                  className="cursor-pointer underline hover:text-brand-main-hover"
                >
                  Ссылка на договор оферту
                </a>
              </span>
            </div>
          </div>
          <div>
            <p className="text-[18px]">Информация по заказу</p>
            <div className="font-second mt-4 flex flex-col gap-2">
              {order.cart.cart_items.map((cardItem) => (
                <div
                  key={cardItem.item.article}
                  className="grid h-8 grid-cols-[1fr_1fr] items-center border-b"
                >
                  <span className="text-sm leading-[120%]">
                    {cardItem.item.name}
                  </span>
                  <span className="text-right text-[16px] font-bold">
                    {cardItem.item.price} ₽
                  </span>
                </div>
              ))}
              <div className="grid h-8 grid-cols-[1fr_1fr] items-center ">
                <span className="text-sm leading-[120%]"></span>
                <span className="text-right text-[16px] font-bold">
                  {order.cart.cart_sum} ₽
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/5">
          <p className="text-[18px]">График платежей</p>
          <div className="font-second relative mt-4 flex flex-col gap-2">
            {!!order &&
              !!stage &&
              order.paymentPlan.map((paymentPlan) => (
                <div
                  key={Math.random()}
                  className={cx(
                    'grid h-8 grid-cols-[1fr_1fr_1fr] items-center border-b',
                    stage.stage! > paymentPlan.stage && 'opacity-20',
                  )}
                >
                  <span className="text-sm leading-[120%]">
                    {format(new Date(paymentPlan.date), 'dd.MM.yyyy')}
                  </span>
                  <span className="text-right text-sm">Сумма платежа</span>
                  <span className="text-right text-[16px] font-bold">
                    {stage.stage === paymentPlan.stage && stage.isPartial
                      ? paymentPlan.sum - stage.paid
                      : paymentPlan.sum}
                    ₽
                  </span>
                  {stage.stage === paymentPlan.stage && (
                    <Button
                      className="absolute -right-52 h-9"
                      onClick={() =>
                        paymentMutation.mutate(
                          stage.isPartial
                            ? paymentPlan.sum - stage.paid
                            : paymentPlan.sum,
                        )
                      }
                    >
                      Оплатить
                    </Button>
                  )}
                </div>
              ))}
            <ButtonColored
              className="mt-4 w-[233px]"
              onClick={() => setIsOpen(true)}
            >
              Оплатить досрочно
            </ButtonColored>
          </div>
        </div>
      </div>
      <PayDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
};
