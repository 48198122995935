import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';

import { Field, Input, Label } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthApi } from 'shared/api';
import { onAxiosError } from 'shared/lib';
import { RoutesPath } from 'shared/routes-path';
import type { ChangePassword } from 'shared/typings';
import { Button, ButtonBack } from 'shared/ui';

const schema = yup.object({
  newPassword: yup
    .string()
    .trim()
    .min(4)
    .required('Введите минимум 10 символов'),
  confirmNewPassword: yup
    .string()
    .trim()
    .min(4)
    .required('Введите минимум 10 символов'),
});

export const StepChangePassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const signInMutation = useMutation({
    mutationFn: (data: ChangePassword) => AuthApi.changePassword(data),
    onSuccess: (data) => {
      if (data.success) {
        navigate(RoutesPath.AUTH);
      }
    },
    onError: onAxiosError,
  });

  const onSubmit = async (data: ChangePassword) => {
    if (data.newPassword !== data.confirmNewPassword) {
      toast.info('Пароли не совпадают');
      return;
    }
    signInMutation.mutate({ newPassword: data.newPassword });
  };

  return (
    <form
      className="auth-form animate__animated animate__fadeIn bg-brand-1"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="auth-form__header form-header">
        <p className="form-header__header">Восстановление пароля</p>
      </div>
      <div className="auth-form__body form-body">
        <Field className="flex w-full flex-col gap-2">
          <Label className="text-sm/6 font-medium text-white">
            Новый пароль
          </Label>
          <Input
            {...register('newPassword')}
            placeholder="Введите данные"
            className={cx(
              'flex items-center rounded-lg border border-white/5 bg-white/5 py-1.5 px-3 text-sm/6 text-white h-12 w-full',
              'focus:outline-none hover:border-white/50',
            )}
          />
        </Field>
        <Field className="flex w-full flex-col gap-2">
          <Label className="text-sm/6 font-medium text-white">
            Подтвердите новый пароль
          </Label>
          <Input
            {...register('confirmNewPassword')}
            placeholder="Введите данные"
            className={cx(
              'flex items-center rounded-lg border border-white/5 bg-white/5 py-1.5 px-3 text-sm/6 text-white h-12 w-full',
              'focus:outline-none hover:border-white/50',
            )}
          />
        </Field>
        {errors &&
          Object.values(errors).map(({ message }) => (
            <span key={message} className="auth-form__error">
              {message}
            </span>
          ))}
      </div>
      <div className="auth-form__footer form-actions">
        <Button
          className=" grid h-12 w-fit items-center justify-self-center px-0 text-xl text-white"
          text="Восстановить пароль"
          disabled={signInMutation.isPending}
        />
        <ButtonBack />
      </div>
    </form>
  );
};
