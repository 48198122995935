import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { AxiosError } from 'axios';
import * as yup from 'yup';

import { Field, Input } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthApi } from 'shared/api';
import { useAuthContext } from 'shared/hooks';
import { RoutesPath } from 'shared/routes-path';
import type { LoginData } from 'shared/typings';
import { ButtonColored } from 'shared/ui';

const schema = yup.object({
  username: yup.string().lowercase().trim().min(4).required(),
  password: yup.string().trim().min(4).required(),
});

export const AuthPage = () => {
  const { setIsAuth } = useAuthContext();
  const navigate = useNavigate();
  // @ts-ignore
  const isDevMode = import.meta.env.DEV;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const signInMutation = useMutation({
    mutationFn: (data: LoginData) => AuthApi.login(data),
    onSuccess: (data) => {
      setIsAuth(true);
      navigate(RoutesPath.INSTALLMENT);

      sessionStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
    },
    onError: (error: AxiosError) => {
      console.error('Sign in error:', error);

      const isRefreshExists = !!localStorage.getItem('refreshToken');
      if (error?.response?.status === 401) {
        !isRefreshExists && localStorage.removeItem('refreshToken');
        // @ts-ignore
        toast.error(error?.response?.data.errorMessage);
      }
    },
  });

  const onSubmit = (data: LoginData) => {
    signInMutation.mutate({
      ...data,
      username: `+${data.username.replace(/\D/g, '')}`,
    });
  };

  return (
    <div className="flex size-full">
      <form
        className="m-auto flex w-[600px] flex-col gap-4 self-center bg-white p-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="flex flex-col text-justify text-xl font-normal md:text-2xl">
          <span className="text-[30px]">Вход</span>
          <span className="pt-2 text-[16px] text-[#727272]">
            Введите данные пользователя
          </span>
        </p>
        <div className="flex flex-col gap-4">
          <Field className="flex w-full flex-col gap-2">
            <InputMask
              mask="+7 (999) 999-99-99"
              {...register('username')}
              defaultValue={isDevMode ? '+79223498488' : ''}
              placeholder="Введите данные"
              className={cx(
                'flex items-center border border-[#575757] py-1.5 px-3 text-sm/6 text-[#727272] h-[60px] rounded-md w-full',
                'focus:outline-none hover:border-brand-main-hover',
              )}
            >
              {(props: any) => (
                <Input {...props} placeholder="+7 (999) 999-99-99" />
              )}
            </InputMask>
          </Field>
          <Field className="flex w-full flex-col gap-2">
            <Input
              {...register('password')}
              type="password"
              defaultValue={isDevMode ? 'TuTSZqgt' : ''}
              placeholder="Введите данные"
              className={cx(
                'flex items-center border border-[#575757] py-1.5 px-3 text-sm/6 text-[#727272] h-[60px] rounded-md w-full',
                'focus:outline-none hover:border-brand-main-hover',
              )}
            />
          </Field>
          <p className="text-sm text-[#727272]">
            Забыли пароль?
            <Link
              to={RoutesPath.RECOVERY_PASSWORD}
              className="whitespace-nowrap pl-4 text-brand-main-color underline"
            >
              Восстановить пароль
            </Link>
          </p>
          {errors &&
            Object.values(errors).map(({ message }) => (
              <span key={message} className="">
                {message}
              </span>
            ))}
        </div>
        <ButtonColored text="Войти" disabled={signInMutation.isPending} />
      </form>
    </div>
  );
};
