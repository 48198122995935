import { Tabs } from 'antd';

import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { LogoutButton } from 'features/logout';

import { useAuthContext } from 'shared/hooks';
import { RoutesPath } from 'shared/routes-path';
import { Button, ButtonColored } from 'shared/ui';
import { Logo } from 'shared/ui/logo';

import appGallery from './assets/app-gallery.png';
import appStore from './assets/app-store.png';
import googlePlay from './assets/google-play.png';
import ruStore from './assets/ru-store.png';

export const Layout = () => {
  const navigate = useNavigate();
  const { isAuth } = useAuthContext();

  useEffect(() => {
    if (!isAuth) {
      navigate(RoutesPath.AUTH);
    }
  }, [isAuth]);

  const onChange = (key: string) => {
    navigate(key);
  };

  const items = [
    {
      key: RoutesPath.INSTALLMENT,
      label: 'Текущая рассрочка',
    },
    {
      key: RoutesPath.PROFILE,
      label: 'Профиль',
    },
    {
      key: RoutesPath.HISTORY,
      label: 'История',
    },
  ];

  return (
    <div className="relative h-full">
      <header className="h-fit bg-white">
        <nav className="mx-auto flex h-24 w-full max-w-screen-2xl items-center justify-between border-gray-200 px-16">
          <div className="flex items-center gap-4">
            <Logo onClick={() => navigate('/')} />
            <div className="hidden w-full items-center justify-between md:flex md:w-auto">
              <ul className="md:0 mt-4 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 font-medium md:mt-0 md:flex-row md:space-x-8 md:border-0 md:bg-white md:p-0 rtl:space-x-reverse">
                {[
                  { text: 'О нас', link: '/' },
                  { text: 'Партнерам', link: '/' },
                  { text: 'FAQ', link: '/' },
                ].map((item) => (
                  <li key={item.text}>
                    <a
                      href={item.link}
                      className="block rounded px-3 py-2  text-brand-1 hover:underline"
                      aria-current="page"
                    >
                      {item.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {!isAuth ? (
            <div className="flex items-center gap-1">
              <Button onClick={() => navigate(RoutesPath.AUTH)}>Войти</Button>
              <ButtonColored>Регистрация</ButtonColored>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              {/*<span className="size-14 overflow-hidden rounded-full bg-brand-text-02/25"></span>*/}
              {/*<span>Иван Иванов</span>*/}
              <LogoutButton />
            </div>
          )}
        </nav>
      </header>
      <div className="flex min-h-full flex-col bg-white">
        <div className="flex flex-col gap-5 bg-[#efefef]">
          <div className="mx-auto w-full max-w-screen-2xl px-16">
            <h2 className="mt-12 text-[42px] font-bold text-black">
              Личный кабинет
            </h2>
            <Tabs
              defaultActiveKey={RoutesPath.INSTALLMENT}
              items={items}
              onChange={onChange}
            />
          </div>
        </div>
        <main className="relative mx-auto w-full max-w-screen-2xl grow px-16 py-20">
          <Outlet />
        </main>
      </div>
      <footer className="bg-[#d9d9d9] py-10">
        <div className="mx-auto grid w-full max-w-screen-2xl grid-cols-[3fr_1fr] px-16">
          <div className="">
            <p className="text-[18px]">
              ООО МКК «ФИНМОЛЛ». ОГРН:1117746509638. ИНН: 7705954143
            </p>
            <p className="mt-0.5 text-[18px]">
              Юридический адрес: 630112, Россия, г. Новосибирск, ул. Фрунзе, д.
              242, помещение 406
            </p>
            <ul className="m-0 mt-10 list-none">
              <li className="text-[18px] underline">
                <a href="/">Договор поручения на приобретение товара</a>
              </li>
              <li className="text-[18px] underline">
                <a href="/">Пользовательское соглашение</a>
              </li>
              <li className="text-[18px] underline">
                <a href="/">Политика конфиденциальности</a>
              </li>
              <li className="text-[18px] underline">
                <a href="/">Политика обработки персональных данных</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-end">
            <Logo />
            <span className="mt-4 text-[35px] font-bold">8 800 111 11 11</span>
            <span className="mt-2 text-[28px] font-bold">info@4kari.ru</span>
          </div>
        </div>
        <div className="mx-auto grid w-full max-w-screen-2xl grid-cols-[3fr_1fr] px-16 pt-16">
          <div className="flex items-center gap-2">
            <a href="/" className="h-10 w-fit cursor-pointer">
              <img src={appGallery} alt="appGallery" />
            </a>
            <a href="/" className="h-10 w-fit cursor-pointer">
              <img src={appStore} alt="appStore" />
            </a>
            <a href="/" className="h-10 w-fit cursor-pointer">
              <img src={googlePlay} alt="googlePlay" />
            </a>
            <a href="/" className="h-10 w-fit cursor-pointer">
              <img src={ruStore} alt="ruStore" />
            </a>
          </div>
          <span className="text-right text-[18px]">© «Kari частями», 2024</span>
        </div>
      </footer>
    </div>
  );
};
