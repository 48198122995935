import { axiosInstance } from 'shared/api/axios.defaults';
import {
  ApproveCode,
  ChangePassword,
  LoginData,
  LogoutData,
  RecoveryPassword,
} from 'shared/typings';

let login: string | null = null;

export class AuthApi {
  static async login(params: LoginData): Promise<any> {
    const { data } = await axiosInstance.post('/auth/login', params);
    return data;
  }

  static async logout(params: LogoutData) {
    const { data } = await axiosInstance.post('/auth/logout', params);
    return data;
  }

  static async recovery(
    params: RecoveryPassword,
  ): Promise<{ success: boolean }> {
    login = params.login;
    const { data } = await axiosInstance.post('/auth/recovery', params);
    return data;
  }

  static async approveCode(params: ApproveCode): Promise<{ success: boolean }> {
    const { data } = await axiosInstance.post('/auth/approve-code', {
      ...params,
      login,
    });
    return data;
  }

  static async changePassword(
    params: ChangePassword,
  ): Promise<{ success: boolean }> {
    const { data } = await axiosInstance.post('/auth/change-password', {
      ...params,
      login,
    });
    login = null;
    return data;
  }

  static async signOut() {
    return axiosInstance.post('/auth/logout', {
      accessToken: sessionStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
    });
  }
}
