import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';

import { Field, Input } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { StepType } from 'pages/recovery-page/ui';
import { Step } from 'pages/recovery-page/ui/steps';

import { AuthApi } from 'shared/api';
import { onAxiosError } from 'shared/lib';
import type { ApproveCode } from 'shared/typings';
import { ButtonBack, ButtonColored } from 'shared/ui';

const schema = yup.object({
  code: yup.string().trim().length(6).required('Введите минимум 6 символов'),
});

export const StepApproveCode = ({
  setStep,
}: {
  setStep: (step: StepType) => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const signInMutation = useMutation({
    mutationFn: (data: ApproveCode) => AuthApi.approveCode(data),
    onSuccess: (data) => {
      if (data.success) {
        setStep(Step.CHANGE_PASSWORD);
      }
    },
    onError: onAxiosError,
  });

  const onSubmit = async (data: ApproveCode) => {
    signInMutation.mutate(data);
  };

  return (
    <div className="flex size-full">
      <form
        className="m-auto flex w-[600px] flex-col gap-4 self-center bg-white p-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="flex flex-col text-justify text-xl font-normal md:text-2xl">
          <span className="text-[30px]">Восстановление пароля</span>
        </p>
        <div className="flex flex-col gap-4">
          <Field className="flex w-full flex-col gap-2">
            <InputMask
              mask="9999"
              {...register('code')}
              placeholder="Код подтверждения"
              className={cx(
                'flex items-center border border-[#575757] py-1.5 px-3 text-sm/6 text-[#727272] h-[60px] rounded-md w-full',
                'focus:outline-none hover:border-brand-main-hover',
              )}
            >
              {(props: any) => <Input {...props} placeholder="9999" />}
            </InputMask>
          </Field>
          {errors &&
            Object.values(errors).map(({ message }) => (
              <span key={message} className="">
                {message}
              </span>
            ))}
        </div>
        <div className="flex gap-2">
          <ButtonColored
            text="Подтвердить"
            disabled={signInMutation.isPending}
          />
          <ButtonBack />
        </div>
      </form>
    </div>
  );
};
